import type { Dispatch, SetStateAction } from 'react';
import { SurveyFormPartialType } from '../SurveyForm.types';


export const handleSurveyFormChange = (changedFields: Record<string, string | Record<string, string>>, setFormValues: Dispatch<SetStateAction<SurveyFormPartialType>>) => {
  const [[name, value]] = Object.entries(changedFields);
  
  if (typeof value === 'object') {
    const entries = Object.entries(value);
    // Workaround if the array of entries is empty
    const entry = entries?.[0];
    const nestedName = entry?.[0];
    const nestedValue = entry?.[1];
    
    if (typeof nestedValue === 'object') {
      return setFormValues(prevState => {
        if (name in prevState) {
          // item
          const objectField = prevState[name as keyof typeof prevState] || {};
          const nestedObjectField: object = objectField[nestedName as keyof typeof objectField] || {};
          if (nestedName in objectField) {
            // item.price || item.industry
            if (Array.isArray(nestedValue)) {
              return {
                ...prevState,
                [name]: { ...objectField, [nestedName]: [...nestedValue] },
              }
            }
            return {
              ...prevState,
              [name]: { ...objectField, [nestedName]: { ...nestedObjectField, ...(nestedValue as object) } },
            }
          } else {
            if (Array.isArray(nestedValue)) {
              return {
                ...prevState,
                [name]: { ...objectField, [nestedName]: [...nestedValue] },
              }
            }
            
            return {
              ...prevState,
              [name]: { ...objectField, [nestedName]: { ...(nestedValue as object) } },
            }
          }
        } else {
          return { ...prevState, [name]: { ...value } };
        }
      });
    } else {
      if (Array.isArray(value)) {
        return setFormValues(prevState => ({
          ...prevState,
          [name]: [...value]
        }));
      }
      
      return setFormValues(prevState => {
        if (name in prevState) {
          const objectField = prevState[name as keyof typeof prevState] || {};
          
          return {
            ...prevState,
            [name]: { ...objectField, ...value },
          }
        } else {
          return { ...prevState, [name]: { ...value } };
        }
      });
    }
  }
  
  return setFormValues(prevState => {
    return {
      ...prevState,
      ...changedFields
    }
  });
};
