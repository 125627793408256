import styled from 'styled-components';
import { BREAKPOINTS } from '../../../theme/breakpoints';


export const StyledSurveyFormActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
  margin-top: 72px;

  @media (max-width: ${BREAKPOINTS.md}) {
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
  }
`;
