import type { FC } from 'react';
import { StyledProgress, StyledProgressLabel, StyledProgressLabelPercentage } from './Progress.styles';
import { ProgressProps as AntDProps } from 'antd';
import { useTranslation} from 'react-i18next';


interface ProgressProps extends AntDProps {
}

export const Progress: FC<ProgressProps> = (props) => {
  const {t} = useTranslation();
  
  return (
    <StyledProgress {...props} format={percent => {
      return <StyledProgressLabel>
        {t('additional.progress')}: •&nbsp;
        <StyledProgressLabelPercentage>{percent}%</StyledProgressLabelPercentage>
      </StyledProgressLabel>
    }}
    />
  );
};
