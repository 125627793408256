import styled from 'styled-components';
import { BREAKPOINTS } from '../../../theme/breakpoints';


export const StyledFigure = styled.figure`
  width: fit-content;
`;

export const StyledImage = styled.img<{ isLoading: boolean }>`
  max-height: 300px;
  border-radius: 4px;
  display: ${({ isLoading }) => isLoading ? 'none' : ''};
  object-fit: contain;

  @media (max-width: ${BREAKPOINTS.md}) {
    height: auto;
    max-width: 100%;
  }
`;

export const StyledCaption = styled.figcaption`
  background-color: #D5F5E7FF;
  color: #3F3F41FF;
  border-radius: 4px;
  padding: 4px 10px;
  margin-top: 4px;
`;
