import type { FC, ReactNode } from 'react';
import { Layout } from 'antd';
import { StyledAppLayoutContent, StyledAppLayoutHeader } from './AppLayout.styles';
import { Logo } from '../../atoms/Logo';


interface AppLayoutProps {
  children: ReactNode;
}

export const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  return (
    <Layout>
      <StyledAppLayoutHeader>
        <Logo />
      </StyledAppLayoutHeader>
      
      <StyledAppLayoutContent>
        {children}
      </StyledAppLayoutContent>
    </Layout>
  );
};
