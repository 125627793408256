import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import Routes from './Routes';
import { AppLayout } from './components/templates/AppLayout';
import { GlobalStyles, theme, antDesignTheme } from './theme';
import './App.css';
import { setupAxios } from './config/axios';
import 'antd/dist/reset.css';


setupAxios();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={antDesignTheme}>
          <GlobalStyles />
          <Router>
            <AppLayout>
              <Routes />
            </AppLayout>
          </Router>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
