/* eslint-disable max-len */
import { SOCIAL_NETWORKS_OPTIONS } from '../socialNetworkOptions';


const productSurveyConfig = {
  type: 'product',
  steps: [
    {
      step: 'intro',
      body: [
        'Thank you for participating in this survey. This is a short study that is intended to learn about needs and wants of golfers when purchasing [item.name]',
        'Please answer the following short background questions, which will help determine if you qualify for the study.',
      ]
    },
    {
      step: 'handicapIndex',
      skipOptionsShuffle: true,
      categories: {
        general: {
          title: 'Please select where your handicap index falls from the list below.  If you do not have a handicap index, please estimate your average score and subtract 72, then select the appropriate range.',
          options: [
            { label: '5 or Under', value: '5-or-under' },
            { label: '5.1-10', value: '5.1-10' },
            { label: '10.1-15', value: '10.1-15' },
            { label: '15.1-20', value: '15.1-20' },
            { label: '20.1-25', value: '20.1-25' },
            { label: '26+', value: '26+' },
          ]
        },
      }
    },
    {
      step: 'purchaseAmount',
      skipOptionsShuffle: true,
      categories: {
        general: {
          title: 'How many new [item.name] do you buy over the course of a year?',
          options: '[item.purchaseAmount]',
          defaultOptions: [
            { label: 'I don\'t buy new [item.name]', value: 'none', disqualify: true },
          ]
        },
      }
    },
    // {
    //   // Disqualification question #1
    //   step: 'DQ1',
    //   skipOptionsShuffle: true,
    //   categories: {
    //     general: {
    //       title: 'According to the rules of golf, what is the maximum number of clubs you are allowed have in your bag when playing a round of golf?',
    //       options: [
    //         { label: 'Maximum of 12', value: '12', disqualify: true },
    //         { label: 'Maximum of 13', value: '13', disqualify: true },
    //         { label: 'Maximum of 14', value: '14' },
    //         { label: 'Maximum of 15', value: '15', disqualify: true },
    //         { label: 'Maximum of 16', value: '16', disqualify: true },
    //         { label: 'Maximum of 17', value: '17', disqualify: true },
    //         { label: 'I don’t know', value: 'don’t_know', disqualify: true },
    //       ]
    //     },
    //   }
    // },
    {
      step: 'playFrequency',
      skipOptionsShuffle: true,
      categories: {
        general: {
          title: 'How many 18 hole rounds of golf did you play in the year 2023?',
          options: [
            { label: '0', value: '0', disqualify: true },
            { label: '1-10', value: '1-10' },
            { label: '10-30', value: '10-30' },
            { label: '31-50', value: '31-50' },
            { label: '51-70', value: '51-70' },
            { label: '71+', value: '71+' },
          ]
        },
      }
    },
    // {
    //   // Disqualification question #2
    //   step: 'DQ2',
    //   skipOptionsShuffle: true,
    //   categories: {
    //     general: {
    //       title: 'What is it called when you have a score that is 1 under par on a single hole?',
    //       options: [
    //         { label: 'Birdie', value: 'birdie' },
    //         { label: 'Eagle', value: 'eagle', disqualify: true },
    //         { label: 'Albatross', value: 'albatross', disqualify: true },
    //         { label: 'Falcon', value: 'falcon', disqualify: true },
    //         { label: 'Swan', value: 'swan', disqualify: true },
    //         { label: 'I don’t know', value: 'don’t_know', disqualify: true },
    //       ]
    //     },
    //   }
    // },
    // {
    //   step: 'competition',
    //   categories: {
    //     general: {
    //       title: 'Which of the following golf ball brands do you play most frequently?',
    //       options: '[item.competition]',
    //       defaultOptions: [
    //         { label: 'I don’t know / Other', value: 'other', disqualify: true },
    //       ]
    //     },
    //   }
    // },
    {
      step: 'qualificationComplete',
      body: [
        'Thank you for answering the background questions. You qualify to participate in the survey!',
        'As mentioned before, this is a short study on [item.name]. Manufacturers of premium [item.name] want to gain a more in-depth understanding of your needs and wants so that they can provide you with products that better meet your needs.',
      ]
    },
    {
      step: 'productModel',
      categories: {
        general: {
          title: 'From the list below, which of the [item.name] models do you play frequently? (select one)',
          options: '[item.productModels]',
          defaultOptions: [
            { label: 'I don’t play any of the above models', value: 'none', disqualify: true },
          ]
        },
      }
    },
    {
      // step: 'feature',
      step: 'attribute1',
      categories: {
        general: {
          title: 'Please choose the most important factor from the list below when deciding which [item.name] to buy',
          options: '[item.attribute1]',
        },
      }
    },
    {
      // step: 'uniqueFeature',
      step: 'attribute2',
      categories: {
        general: {
          title: 'Which of the factors in the list below matters the most when choosing which [item.name] you will buy?',
          options: '[item.attribute2]',
          defaultOptions: [
            {
              label: 'I don\'t take any of the provided options into account when purchasing [item.name].',
              value: 'none'
            },
          ]
        },
      }
    },
    {
      step: 'salesChannels',
      categories: {
        general: {
          title: 'Where do you prefer to purchase [item.name]?',
          options: '[item.salesChannels]',
        },
      }
    },
    {
      step: 'socialMedia',
      categories: {
        general: {
          title: 'Which of the following social media networks do you use?',
          subTitle: 'Please check all that apply',
          titleShort: 'Social Media Preference',
          multiple: true,
          options: SOCIAL_NETWORKS_OPTIONS,
        },
      }
    },

    {
      step: 'price.low',
      type: 'danger',
      intro: 'Imagine you are about to buy new [item.name], [item.description].',
      categories: {
        general: {
          body: [
            'At what price would you say:',
            // 'At what price for a [item.name] that you play the most, would you say:',
            '“The price is so low that I’m worried that the quality of the [item.name] might not be very good, so I won’t buy them”?',
          ]
        },
      }
    },
    {
      step: 'price.high',
      type: 'danger',
      body: [
        // TODO: Add quantity before [item.name]
        'At what price for the [item.name] you play the most, would you say:',
        '“The [item.name] are too expensive and out of my budget. So regardless of how good the [item.name] are, I won’t buy them”?',
      ]
    },
    {
      step: 'price.midHigh',
      type: 'success',
      body: [
        'Now, what price would you consider to be *high, but not too high* for [item.name],',
        // 'Now, what price for the [item.name] would you consider *high, but not too high*.',
        'a price that is affordable but stretches your budget?'
        // 'This is the price that is affordable with a stretch of your budget.'
      ]
    },
    {
      step: 'price.midLow',
      type: 'success',
      body: [
        'Lastly, what price for the [item.name] would you consider *low, but not too low* to the point where you would start doubting its quality?',
        'In other words, this is the price that you consider a *great bargain*.'
      ]
    },
  ]
};

export const golfSurveyConfig = {
  product: productSurveyConfig,
  service: productSurveyConfig,
};

// export type GolfCategorySurveyConfig = typeof productSurveyConfig;
export type GolfCategorySurveyConfig = typeof golfSurveyConfig.product | typeof golfSurveyConfig.service;
// export type CategorySurveyStepConfigType = CategorySurveyConfig['steps'][number];
