import styled from 'styled-components';
import { Button } from 'antd';
import { ButtonProps } from './Button.types';
import {
  getBackgroundColorDisabled,
  getBorder,
  getBorderHover,
  getFontSize,
  getHeight,
  getPadding,
  getTextColor,
  getTextColorHover
} from './Button.utils';


export const StyledButton = styled(({ variant, ...rest }) => <Button {...rest} />)<ButtonProps>`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    color: ${({ theme, variant, type }) => getTextColor(theme, variant || type)};
    border: ${({ theme, type, variant }) => getBorder(theme, variant || type)};

    padding: ${({ size }) => getPadding(size)};
    height: ${({ size }) => getHeight(size)};

    font-family: Inter, sans-serif;
    font-weight: ${({ type }) => type === 'default' ? 600 : 700};
    font-size: ${({ size }) => getFontSize(size)};


    &&:not(:disabled):hover {
      border: ${({ theme, type, variant }) => getBorderHover(theme, variant || type)};
      color: ${({ theme, type, variant }) => getTextColorHover(theme, variant || type)};
    }

    &&:disabled {
      background-color: ${({ theme, type, variant }) => getBackgroundColorDisabled(theme, variant || type)};
      border: ${({ theme, type, variant }) => getBorder(theme, variant || type, true)};
      color: ${({ theme, variant, type }) => getTextColor(theme, variant || type, true)};

      :hover {
        border: ${({ theme, type, variant }) => getBorderHover(theme, variant || type, true)};
        color: ${({ theme, type, variant }) => getTextColorHover(theme, variant || type, true)};
      }
    }
  }

  &&.ant-btn-link {
    text-decoration: underline;
  }
`;
