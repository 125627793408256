import type { StepsSequenceType } from '../../../pages/Survey/Survey.types';
import type { SurveyFormPartialType } from '../SurveyForm.types';
import { validateArrayField, validateSimpleField } from './formFieldsValidators';


export const validateAddProjectFormCurrentStepFields = (formValues: SurveyFormPartialType, currentStepName?: StepsSequenceType[number] | string) => {
  switch (currentStepName) {
    case 'intro':
      return true;
    case 'isInterested':
    case 'usageFrequency':
    case 'feature':
    case 'uniqueFeature':
    case 'disadvantage':
    case 'usage':
    case 'retailLocation':
    case 'competitor':
    case 'retailPeriod':
    case 'paymentMethod':
      const keyOfFormValues = currentStepName as keyof typeof formValues;

      return validateSimpleField(formValues, keyOfFormValues);
    case 'socialMedia':
      return validateArrayField(formValues, 'socialMedia', 1);
    case 'price.low':
    case 'price.high':
    case 'price.midHigh':
    case 'price.midLow':
      if (!formValues.price) {
        return false;
      }
      const splitStepName = currentStepName.split('.');
      const key = splitStepName[1] as keyof typeof formValues.price;

      return validateSimpleField(formValues.price, key);
    default: {
      const keyOfFormValues = currentStepName as keyof typeof formValues;

      if (keyOfFormValues) {
        return validateSimpleField(formValues, keyOfFormValues);
      }

      return true;
    }
  }
};
