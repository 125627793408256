import type { FC } from 'react';
import { ButtonProps } from './Button.types';
import { StyledButton } from './Button.styles';


export const Button: FC<ButtonProps> = ({ ...rest }) => {
  return (
    <StyledButton {...rest} />
  );
};
