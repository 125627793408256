import styled, { css } from 'styled-components';
import { Form } from 'antd';
import InfoCircleRedIcon from '../../../assets/icons/info-circle-red.svg';
import { FormItemProps } from './FormItem.types';
import { BREAKPOINTS } from '../../../theme/breakpoints';


export const StyledFormItem = styled(({
  subTitle,
  largeLabel,
  hideInputControl,
  errorMessageTextAlign,
  errorMessageJustify,
  labelDisplayBlock,
  ...rest
}) => <Form.Item {...rest} />)<FormItemProps>`
  margin-bottom: 20px;

  & .ant-form-item-label > label {
    font-family: Manrope, sans-serif;
    height: 100%;
    width: 100%;
    padding: 16px 0;
    font-size: ${({ largeLabel }) => largeLabel ? '30px' : ''};
    font-weight: ${({ largeLabel }) => largeLabel ? 700 : ''};

    display: ${({ labelDisplayBlock }) => labelDisplayBlock && 'block'};

    @media (max-width: ${BREAKPOINTS.md}) {
      font-size: ${({ largeLabel }) => largeLabel ? '26px' : ''};
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
      font-size: ${({ largeLabel }) => largeLabel ? '22px' : ''};
    }
  }

  & .ant-form-item-explain-error {
    display: flex;
    justify-content: ${({ errorMessageJustify }) => errorMessageJustify || 'end'};
    align-items: center;
    gap: 4px;
    color: ${({ theme }) => theme.color.states.red};
    text-align: ${({ errorMessageTextAlign }) => errorMessageTextAlign || 'right'};
    margin-top: 6px;
    margin-bottom: 4px;

    ::before {
      content: url(${InfoCircleRedIcon});
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }

  &&& .ant-form-item-tooltip {
    margin-left: auto;
    color: ${({ theme }) => theme.color.primary.blue80};
  }

  ${({ subTitle }) => subTitle && css`
    && .ant-form-item-label::after {
      font-family: Manrope, sans-serif;
      display: block;
      content: '${subTitle}';

      font-size: 24px;
      font-weight: 500;

      @media (max-width: ${BREAKPOINTS.sm}) {
        font-size: 20px;
      }
    }
  `}

  ${({ hideInputControl }) => hideInputControl && css`
    && .ant-form-item-control-input {
      display: none;
    }
  `}
`;
