import type { FC } from 'react';
import type { CardProps as AntDCardProps } from 'antd';
import { StyledCard } from './Card.styles';


interface CardProps extends AntDCardProps {
  width?: string;
}

export const Card: FC<CardProps> = ({ width, ...rest }) => {
  return (
    <StyledCard width={width} {...rest} />
  );
};
