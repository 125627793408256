import styled from 'styled-components';
import { ReactComponent as SuccessStatusIcon } from '../../../assets/icons/status-success.svg';
import { BREAKPOINTS } from '../../../theme/breakpoints';


export const StyledSurveyFormSuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
`;

export const StyledSurveyFormSuccessTitle = styled(({ addMarginTop, children, ...rest }) =>
  <h1 {...rest}>{children}</h1>)<{ addMarginTop?: boolean }>`
  color: ${({ theme }) => theme.color.greyscale.grey80};
  font-family: Manrope, sans-serif;
  font-weight: 600;
  font-size: 48px;
  font-feature-settings: 'liga' off;
  margin: ${({ addMarginTop }) => addMarginTop && '50px'};
`;

export const StyledSurveyFormSuccessMessage = styled.p`
  color: ${({ theme }) => theme.color.greyscale.grey80};
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-feature-settings: 'liga' off;
  text-align: center;
`;

export const StyledSurveyFormSuccessIcon = styled(SuccessStatusIcon)`
  height: 195px;
  width: 195px;

  @media (max-width: ${BREAKPOINTS.md}) {
    font-size: 26px;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 150px;
    width: 150px;
  }
`;
