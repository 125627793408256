import type { FC } from 'react';
import type { SpinProps } from 'antd';
import { StyledSpinner, StyledSpinnerOverlay } from './Spinner.styles';


interface SpinnerProps extends SpinProps {
  withOverlay?: boolean
}

export const Spinner: FC<SpinnerProps> = ({ size = 'large', withOverlay = false, ...rest }) => {
  return (
    <>
      {withOverlay ? (
        <StyledSpinnerOverlay>
            <StyledSpinner {...rest} />
          </StyledSpinnerOverlay>
      ) : (
        <StyledSpinner {...rest} />
      )}
    </>
  );
};
