import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import i18n from 'i18next';
import { Card } from '../../atoms/Card';
import { SurveyForm } from '../../organisms/SurveyForm';
import type { Project } from '../../../types/project';
import type { SurveyFormPartialType } from '../../organisms/SurveyForm/SurveyForm.types';
import { SurveyFormType } from '../../organisms/SurveyForm/SurveyForm.types';
import { getProject } from '../../../api/project';
import { Progress } from '../../atoms/Progress';
import { SelectedOptionsIndexesType } from './Survey.types';
import { STEPS_SEQUENCE } from '../../../constants/surveyFormSteps';
import { Spinner } from '../../atoms/Spinner';
import { SurveyType } from '../../../types/survey';
import { updateSurveyResponse } from '../../../api/survey';
import { checkIfRespondentIsEligibleToSurvey } from '../../../api/cint';
import {
  CategorySurveyConfig,
  CategorySurveyStepConfigType,
  paymentMethodStepConfig,
  usageFrequencyStepConfig
} from '../../../constants/surveyConfig';
import { getSurveyConfig } from '../../../helpers/getSurveyConfig';
import { GolfCategorySurveyConfig } from '../../../constants/customSurveyConfigs/golfSuveyConfig';


interface SurveyProps {
  isPreview?: boolean
}

const Survey: FC<SurveyProps> = ({ isPreview }) => {
  const [project, setProject] = useState<Project>();
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState<SurveyFormPartialType>({});
  const [currentCategorySurveyConfig, setCurrentCategorySurveyConfig] = useState<CategorySurveyConfig | GolfCategorySurveyConfig>();
  const [projectSurveyConfig, setProjectSurveyConfig] = useState<CategorySurveyConfig>();

  const [isDisqualified, setIsDisqualified] = useState(false);
  const [selectedOptionsIndexes, setSelectedOptionsIndexes] = useState<SelectedOptionsIndexesType>({});
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const [messageApi, messageContextHolder] = message.useMessage();

  const timerStart = useRef<Date>(new Date());
  const { projectId, respondentId } = useParams();

  const isUseGolfConfig = useMemo(() => {
    return project?.item?.config === 'golf';
  }, [project?.item?.config]);

  useEffect(() => {
    if (project) {
      let productType = project.type;
      const isUseGolfConfig = project.item.config === 'golf';
      const surveyConfig = getSurveyConfig(isUseGolfConfig);

      const categoryConfig = { ...surveyConfig[productType] };

      let surveyCategoryConfigSteps = [...categoryConfig.steps];

      if (project.item.config === 'custom' && projectSurveyConfig) {
        surveyCategoryConfigSteps = [...projectSurveyConfig.steps];
      }

      if (project.aim === 'change_payment_model') {
        const paymentMethodStep = paymentMethodStepConfig[productType] as CategorySurveyStepConfigType;

        const placementIndex = surveyCategoryConfigSteps.findIndex(stepConfig => stepConfig.step.startsWith('price'));

        surveyCategoryConfigSteps.splice(placementIndex, 0, paymentMethodStep);
      }
      if (project.item.usageFrequency) {
        const isInterestedStepIndex = surveyCategoryConfigSteps.findIndex((stepConfig) => stepConfig.step === 'isInterested');
        const usageFrequencyStep = usageFrequencyStepConfig[project.type] as CategorySurveyStepConfigType;

        surveyCategoryConfigSteps.splice(isInterestedStepIndex + 1, 0, usageFrequencyStep)
      }

      if (project.skipWTP) {
        surveyCategoryConfigSteps = surveyCategoryConfigSteps.filter((stepConfig) => !stepConfig.step.startsWith('price'));
      }

      categoryConfig.steps = surveyCategoryConfigSteps as CategorySurveyConfig['steps'];

      setCurrentCategorySurveyConfig(categoryConfig);
    }
  }, [project, projectSurveyConfig]);

  const projectCategoryConfigSteps = useMemo(() => {
    if (!currentCategorySurveyConfig) {
      return null;
    }

    return currentCategorySurveyConfig?.steps?.map(stepConfig => stepConfig.step);
  }, [currentCategorySurveyConfig]);

  const modifiedStepsSequence = useMemo(() => {
    if (project && projectCategoryConfigSteps) {
      const steps = [...projectCategoryConfigSteps, ...STEPS_SEQUENCE];

      if (!project?.item.competitors || !project?.item.competitors.length) {
        const competitorStepKey = 'competitor' as typeof steps[number];
        const competitorsStepIndex = steps.indexOf(competitorStepKey);

        if (competitorsStepIndex !== -1) {
          steps.splice(competitorsStepIndex, 1);
        }
      }

      return steps;
    }

    return [];
  }, [project, projectCategoryConfigSteps]);

  const currentStepName = useMemo(() => modifiedStepsSequence[step - 1], [step, modifiedStepsSequence]);

  useEffect(() => {
    const _getProject = async () => {
      if (projectId) {
        try {
          const { config, ..._project } = await getProject(projectId);

          if (config) {
            setProjectSurveyConfig(config);
          }

          setProject(_project);
        } catch (err) {
          console.log('err', err);
          messageApi.error('Something went wrong, please try again later');
        }
      }
      setIsProjectLoading(false);
    };

    _getProject();
  }, [messageApi, projectId]);

  useEffect(() => {
    if (project) {
      i18n.changeLanguage(project.surveyLanguage);
    }
  }, [project])

  const redirectToReturnUrl = useCallback(() => {
    const CINTReturnUrlTemplate = process.env.REACT_APP_CINT_RESPONDENT_RETURN_URL;

    if (CINTReturnUrlTemplate && respondentId) {
      window.location.href = CINTReturnUrlTemplate.replace('[ID]', respondentId);
    }
  }, [respondentId]);

  useEffect(() => {
    const _checkIfRespondentIsInvitedToSurvey = async () => {
      if (isPreview) {
        return;
      }

      if (projectId && respondentId) {
        try {
          const { isEligible } = await checkIfRespondentIsEligibleToSurvey(projectId, respondentId);

          if (!isEligible) {
            // NOTE: Redirect user to CINT return url if respondent is not invited
            //  or has already finished the survey
            redirectToReturnUrl();
          }

        } catch (err) {
          console.log('err', err);
          messageApi.error('Something went wrong, please try again later');
        }
      }
    };

    _checkIfRespondentIsInvitedToSurvey();
  }, [messageApi, projectId, redirectToReturnUrl, respondentId, isPreview]);

  const countTimeSpent = useCallback((finishedDate: Date) => {
    const startedAt = timerStart.current.getTime();
    const finishedAt = finishedDate.getTime();

    if (startedAt && finishedAt) {
      const resultInMilliseconds = finishedAt - startedAt;
      // result in seconds
      return resultInMilliseconds / 1000;
    }
  }, []);

  const onSubmit = useCallback(async (finishedTime?: Date) => {
    if (isPreview) {
      return;
    }

    if (!respondentId || !projectId) {
      return;
    }

    const metadata: Partial<SurveyType['metadata']> = {
      selectedOptionsIndexes
    };

    if (finishedTime) {
      metadata.timeSpent = countTimeSpent(finishedTime);
    }

    const config = project?.item.config ?? 'default';

    const surveyObject: SurveyType = {
      ...(formValues as SurveyFormType),
      isDisqualified,
      projectId,
      metadata,
      config
    };

    if (isUseGolfConfig) {
      surveyObject.config = 'golf'
    }

    try {
      // TODO: Add cancellation token
      await updateSurveyResponse(projectId, respondentId, surveyObject);
    } catch (err) {
      console.log('err', err);
    }
  }, [
    project?.item.config,
    isPreview,
    respondentId,
    projectId,
    selectedOptionsIndexes,
    formValues,
    isDisqualified,
    isUseGolfConfig,
    countTimeSpent
  ]);

  const handlePreviousStep = useCallback(() => {
    setStep(prevStep => {
      if (prevStep === 1) {
        return prevStep;
      }
      return prevStep - 1;
    });
  }, []);

  const handleNextStep = useCallback(async (isDisqualified?: boolean) => {
    const isLastQuestionStep = step === modifiedStepsSequence.length - 1;
    if (isDisqualified || isLastQuestionStep) {
      onSubmit(new Date());
    }

    if (isDisqualified) {
      return setStep(modifiedStepsSequence.length);
    }

    setStep(prevStep => {
      if (prevStep !== 1 && !isLastQuestionStep) {
        try {
          onSubmit();
        } catch (err) {
          console.log('err', err);
        }
      }

      if (prevStep === modifiedStepsSequence.length) {
        return prevStep;
      }
      return prevStep + 1;
    });
  }, [modifiedStepsSequence.length, onSubmit, step]);

  const isLastStep = useMemo(() => {
    return step === modifiedStepsSequence.length;
  }, [modifiedStepsSequence.length, step]);

  const progress = useMemo(() => {
    if (!project) {
      return 0;
    }

    return Math.ceil((step / modifiedStepsSequence.length) * 100);
  }, [modifiedStepsSequence.length, project, step]);

  const onSelectedOptionIndexChange = useCallback((formItemName: string, index: number) => {
    setSelectedOptionsIndexes(prevState => ({ ...prevState, [formItemName]: index }));
  }, []);

  const handleFinish = useCallback(() => {
    if (isPreview) {
      return;
    }

    redirectToReturnUrl();
  }, [redirectToReturnUrl, isPreview]);

  return (
    <>
      {messageContextHolder}

      <Card>
        {!isLastStep && (
          <Progress percent={progress} />
        )}
        <SurveyForm
          isUseGolfConfig={isUseGolfConfig}
          currentCategorySurveyConfig={currentCategorySurveyConfig}
          projectSurveyConfig={projectSurveyConfig}
          project={project}
          formValues={formValues}
          setFormValues={setFormValues}
          onSelectedOptionIndexChange={onSelectedOptionIndexChange}
          isLastStep={isLastStep}
          currentStepName={currentStepName}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
          step={step}
          handleFinish={handleFinish}
          isDisqualified={isDisqualified}
          setIsDisqualified={setIsDisqualified}
        />
      </Card>

      {isProjectLoading && (
        <Spinner tip="Loading..." size="large" withOverlay spinning />
      )}
    </>
  );
};

export default Survey;
