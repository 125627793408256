import { Typography } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../../../theme/breakpoints';


export const StyledSurveyStepParagraph = styled.p`
  font-family: Manrope, sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: ${({ theme }) => theme.color.greyscale.grey80};

  @media (max-width: ${BREAKPOINTS.md}) {
    font-size: 26px;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

export const StyledSurveyStepHighlightedPhrase = styled(Typography.Text)`
  font-family: Manrope, sans-serif;
  font-weight: 700;
  font-size: 32px;

  @media (max-width: ${BREAKPOINTS.md}) {
    font-size: 26px;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 18px;
  }
`;

export const StyledSurveyStepPriceContentWrapper = styled.div`
  margin-top: 32px;

  @media (max-width: ${BREAKPOINTS.md}) {
    margin-top: 10px;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    margin-top: 0;
  }
`;
