export const color = {
  primary: {
    blue: '#5271FF',
    blue80: '#758DFF',
    blue60: '#97AAFF',
    blue40: '#BAC6FF',
    blue20: '#E6EBFF',
  },
  secondary: {
    purple: '#B678E6',
    purple80: '#C593EB',
    purple60: '#D3AEF0',
    purple40: '#E2C9F5',
    purple20: '#F0E4FA',
  },
  tertiary: {
    blue: '#002C59',
    lightPurple: '#6C7092',
    violet: '#5369D3',
    darkGreen: '#015551',
    green: '#34A853',
    lightGreen: '#17C170',
    teal: '#0ACBCB',
    yellow: '#D6DE87',
    reddishPink: '#E25B61',
    orange: '#FF8744',
  },
  states: {
    green: '#2ECC71',
    red: '#FF1212'
  },
  greyscale: {
    grey: '#1D1D1B',
    grey80: '#48484A',
    grey60: '#7B7B7C',
    grey40: '#A7A7A7',
    grey30: '#D5D5DC',
    grey20: '#E2E2EA',
    grey10: '#F3F3F9',
    grey5: '#FAFAFB',
    white: '#FFFFFF',
  }
};
