export const SOCIAL_NETWORKS_OPTIONS = [
  { label: 'socialNetwork.x', value: 'x' },
  { label: 'socialNetwork.linkedin', value: 'linkedin' },
  { label: 'socialNetwork.youtube', value: 'youtube' },
  { label: 'socialNetwork.snapchat', value: 'snapchat' },
  { label: 'socialNetwork.instagram', value: 'instagram' },
  { label: 'socialNetwork.pinterest', value: 'pinterest' },
  { label: 'socialNetwork.tiktok', value: 'tiktok' },
  { label: 'socialNetwork.facebook', value: 'facebook' },
  { label: 'socialNetwork.none-of-these', value: 'none' },
];
