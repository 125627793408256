import styled from 'styled-components';
import { Radio } from 'antd';
import { BREAKPOINTS } from '../../../theme/breakpoints';


export const StyledRadioGroup = styled(Radio.Group)`
  --fontSize: 20px;
  --radioSize: 22px;

  display: flex;
  flex-direction: column;
  gap: 12px;


  && .ant-radio-wrapper {
    font-family: Inter, sans-serif;
    font-size: var(--fontSize);

    .ant-radio-inner {
      width: var(--radioSize);
      height: var(--radioSize);
    }
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    --fontSize: 18px;
    --radioSize: 22px;
  }
`;
