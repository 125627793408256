import en from './en.json';
import enGB from './en-gb.json';
import de from './de.json';
import sv from './sv.json';
import da from './da.json';
import nb from './nb.json';
import es from './es.json';


export const translations = {
  en, 'en-gb': enGB, de, sv, da, nb, es
};
export const appLangs = Object.keys(translations) as (keyof typeof translations)[];
