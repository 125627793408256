import styled from 'styled-components';
import { Checkbox } from 'antd';


export const StyledCheckboxGroup = styled(Checkbox.Group)`
  flex-direction: column;
  gap: 12px;

  && .ant-checkbox {
    top: unset;
  }

  && .ant-checkbox-group-item {
    margin: 0;
    font-size: 20px;
    font-family: Inter, sans-serif;
    color: ${({ theme }) => theme.color.greyscale.grey80};
    font-feature-settings: 'liga' off;
  }

  && .ant-checkbox-inner {
    width: 22px;
    height: 22px;

    ::after {
      inset-inline-start: 27.5%;
    }
  }

  && .ant-checkbox-wrapper {
    align-items: center;
  }
`;
