import { FC } from 'react';
import { StyledCheckboxGroup } from './CheckboxGroup.styles';
import { CheckboxGroupProps as AntDCheckboxGroupProps } from 'antd/lib/checkbox';


interface CheckboxGroupProps extends AntDCheckboxGroupProps {
  selectedValues?: string[];
}

export const CheckboxGroup: FC<CheckboxGroupProps> = ({ options, selectedValues, ...rest }) => {
  return (
    <StyledCheckboxGroup options={options} {...rest} />
  );
};
