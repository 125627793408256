import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import Confetti from 'react-confetti'
import { useTranslation } from 'react-i18next';
import {
  StyledSurveyFormSuccessContainer,
  StyledSurveyFormSuccessIcon,
  StyledSurveyFormSuccessMessage,
  StyledSurveyFormSuccessTitle
} from './SurveyFormSuccess.styles';


interface SurveyFormSuccessProps {
  isDisqualified?: boolean;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
}

export const SurveyFormSuccess: FC<SurveyFormSuccessProps> = ({
  isDisqualified,
  parentRef,
}) => {
  const { t } = useTranslation();
  const [confettiHeight, setConfettiHeight] = useState(0)
  const [confettiWidth, setConfettiWidth] = useState(0)
  
  useEffect(() => {
    if (parentRef?.current) {
      const { clientHeight, clientWidth } = parentRef.current;
      if (clientHeight && clientWidth) {
        setConfettiHeight(clientHeight);
        setConfettiWidth(clientWidth);
      }
    }
  }, [parentRef])
  
  const message = useMemo(() => {
    return isDisqualified ? `${t('additional.thankYouForYourParticipation')}` : `${t('additional.thankYouForCompleting')}`;
  }, [isDisqualified, t]);
  
  return (
    <>
      {!isDisqualified && confettiWidth && confettiHeight ? (
        <Confetti
          numberOfPieces={200}
          colors={['#17C170']}
          recycle={false}
          width={confettiWidth}
          height={confettiHeight}
          style={{ inset: 'unset' }}
        />
      ) : null}
      <StyledSurveyFormSuccessContainer>
        {!isDisqualified ? (
          <StyledSurveyFormSuccessIcon />
        ) : null}
        <StyledSurveyFormSuccessTitle addMarginTop={isDisqualified}>{t('additional.great')}</StyledSurveyFormSuccessTitle>
        <StyledSurveyFormSuccessMessage>{message}</StyledSurveyFormSuccessMessage>
      </StyledSurveyFormSuccessContainer>
    </>
  );
};
