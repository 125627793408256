import { createGlobalStyle, DefaultTheme } from 'styled-components';
import { color } from './colors';
import { shadow } from './shadows';


export const theme = {
  color,
  shadow,
};

export type ThemeType = typeof theme & DefaultTheme;

export const GlobalStyles = createGlobalStyle`
`;

export const antDesignTheme = {
  token: {
    colorPrimary: color.tertiary.lightGreen,
    colorPrimaryDisabled: color.greyscale.grey30,
  },
  components: {
    Form: {
      fontFamily: 'Inter',
    },
    Input: {
      fontFamily: 'Inter',
      borderRadius: 4,
      backgroundColor: color.greyscale.white,
      colorBorder: color.greyscale.grey30,
      colorPrimaryHover: color.greyscale.grey10,
      colorError: color.states.red,
    },
    InputNumber: {
      fontFamily: 'Inter',
      borderRadius: 4,
      backgroundColor: color.greyscale.white,
      colorBorder: color.greyscale.grey30,
      colorPrimaryHover: color.greyscale.grey10,
      colorError: color.states.red,
    },
    Select: {
      fontFamily: 'Inter',
      borderRadius: 4,
      backgroundColor: color.greyscale.white,
      colorBorder: color.greyscale.grey30,
      colorPrimaryHover: color.greyscale.grey10,
    },
    Typography: {
      colorText: color.greyscale.grey,
      fontFamily: 'Inter',
    },
  }
};
