import { FC, ImgHTMLAttributes, useState } from 'react';
import { StyledCaption, StyledFigure, StyledImage } from './Image.styles';
import { Skeleton } from 'antd';


interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  caption?: string;
}

export const Image: FC<ImageProps> = ({ caption, ...rest }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onImageLoadStart = () => {
    setIsLoading(true);
  };

  const onImageLoaded = () => {
    setIsLoading(false);
  };

  return (
    <StyledFigure>
      {isLoading ? (
        <Skeleton.Image
          active
          style={{ width: 500, height: 300 }}
        />
      ) : null}

      <StyledImage
        {...rest}
        isLoading={isLoading}
        onLoad={onImageLoaded}
        onLoadStart={onImageLoadStart}
      />

      {
        caption ? (
          <StyledCaption>{caption}</StyledCaption>
        ) : null
      }
    </StyledFigure>
  );
};
