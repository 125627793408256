import styled from 'styled-components';
import { Card } from 'antd';
import { BREAKPOINTS } from '../../../theme/breakpoints';


export const StyledCard = styled(({ width, ...rest }) =>
  <Card {...rest} />)`
  && {
    box-shadow: ${({ theme }) => theme.shadow.mildShadow};
    width: ${({ width }) => width};
    padding: 46px 40px 80px 40px;
    margin-bottom: 16px;

    @media (max-width: ${BREAKPOINTS.md}) {
      padding-inline: 30px;
      padding-bottom: 50px;
      margin-bottom: 0;
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
      padding-inline: 24px;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 0;
      width: 100%;
    }
  }

  && .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;
