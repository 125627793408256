import styled from 'styled-components';
import { Layout } from 'antd';
import { BREAKPOINTS } from '../../../theme/breakpoints';


export const StyledAppLayoutHeader = styled(Layout.Header)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: ${({ theme }) => theme.color.greyscale.white};
  }
`;

export const StyledAppLayoutContent = styled(Layout.Content)`
  && {
    padding: 24px 50px 60px;

    // 64px is a header height
    max-height: calc(100vh - 64px);
    min-height: calc(100vh - 64px);
    overflow-x: auto;
    background: ${({ theme }) => theme.color.greyscale.white};

    @media (max-width: ${BREAKPOINTS.md}) {
      padding-inline: 20px;
      padding-bottom: 40px;
      padding-top: 0;
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
      padding: 0;
      min-height: 100%;
    }
  }
`;
