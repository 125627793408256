import type { FC } from 'react';
import reactStringReplace from 'react-string-replace';


export const highlightSpecialPhraseInString = (
  string: string,
  Element: FC<any>,
  type?: 'danger' | 'success',
  regexp = /(\*.+?\*)/g,
) => {
  return reactStringReplace(string, regexp, (match, index) => {
    const key = match.slice(1, -1);
    
    return (
      <Element
        key={`${key}-${index}`}
        type={type}
      >
        {key}
      </Element>
    );
  });
};
