import type { FC } from 'react';
import { StyledLogo } from './Logo.styles';


interface LogoProps {
  width?: string;
  height?: string;
}

export const Logo: FC<LogoProps> = ({ width = '173px', height = '25px' }) => {
  return (
    <StyledLogo width={width} height={height} />
  );
};
