import { Routes, Route, Navigate } from 'react-router-dom';
import Survey from './components/pages/Survey';
import NotFound from './components/pages/NotFound';


const routes = () => (
  <Routes>
    <Route path="/not-found" element={<NotFound />} />
    <Route path="/survey/:projectId/respondent/:respondentId" element={<Survey />} />
    <Route path="/survey/:projectId/preview" element={<Survey isPreview />} />
    <Route path="*" element={<Navigate to="/not-found" replace />} />
  </Routes>
);

export default routes;
