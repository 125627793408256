import { useMemo } from 'react';
import type { FC } from 'react';
import { Radio, RadioGroupProps as AntDRadioGroupProps } from 'antd';
import { StyledRadioGroup } from './RadioGroup.styles';


interface RadioGroupProps extends AntDRadioGroupProps {
  onClick?: (optionIndex: number) => void;
}

export const RadioGroup: FC<RadioGroupProps> = ({ options, onClick, ...rest }) => {
  const renderOptions = useMemo(() => {
    return options?.map((option, index) => {
      if (typeof option === 'object' && typeof option.value === 'string') {
        return <Radio
          key={option.value}
          value={option.value}
          onClick={() => onClick?.(index)}
        >
          {option.label}
        </Radio>
      }
      return null;
    });
  }, [onClick, options]);
  
  return (
    <StyledRadioGroup {...rest}>
      {renderOptions}
    </StyledRadioGroup>
  );
};
