import { surveyConfig } from '../constants/surveyConfig';
import { golfSurveyConfig } from '../constants/customSurveyConfigs/golfSuveyConfig';


export const getSurveyConfig = (isUseGolfConfig?: boolean) => {
  if (isUseGolfConfig) {
    return golfSurveyConfig;
  }

  return surveyConfig;
};
