import type { FC } from 'react';
import { StyledFormItem } from './FormItem.styles';
import { FormItemProps } from './FormItem.types';


export const FormItem: FC<FormItemProps> = (props) => {
  return (
    <StyledFormItem {...props} />
  );
};
