import styled from 'styled-components';
import { Progress } from 'antd';


export const StyledProgress = styled(Progress)`
  display: flex;
  flex-direction: column-reverse;

  && .ant-progress-text {
    margin: 0;
  }

  && .ant-progress-outer {
    border: ${({ theme }) => `1px solid${theme.color.greyscale.grey30}`};
    border-radius: 40px;
    padding-inline: 4px;
    margin-top: 4px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  && .ant-progress-bg {
    background-color: ${({ theme }) => theme.color.tertiary.lightGreen};
  }
`;

export const StyledProgressLabel = styled.span`
  color: ${({ theme }) => theme.color.greyscale.grey60};

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-feature-settings: 'liga' off;
`;

export const StyledProgressLabelPercentage = styled.span`
  color: ${({ theme }) => theme.color.greyscale.grey60};

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  font-feature-settings: 'liga' off;
`;
